import firebase from "gatsby-plugin-firebase";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, AppBar, Button, Card, Dialog, Switch } from "@material-ui/core";
import { Link } from "gatsby";
import HomeAppBar from "../components/HomeAppBar";
import axios from "axios";
import League from "../classes/League";
 

export default class EditDivision extends React.Component {

    defaultnewMatchInfo = {
        homeTeam: "",
        visitorTeam: "",
        matchDate: new Date(),
        startTimeAMPM: "AM",
        startTimeMinute: "00",
        startTimeHour: "9",
        matchName:"My Test Stream",
        //matchDescription:"Jack's first test of the stream",
        streamKey: "",
        streamURL: "rtmp://x.rtmp.youtube.com/live2/",
    publicStreamURL: "",

    }

    state = {
        showAddTeam: false,
        showAddMatch: false,
        showAddPlayer: false,
        newplayerInfo: {},
        newteamInfo: {},
        newMatchInfo: this.defaultnewMatchInfo,
        teams: [],
        matches: [],
        isActive:false,
        streamkeys:{},
        streamKey: "",
        streamURL: "",



    }



    componentDidMount() {
        const urlSearchParams = new URL(window.location);
        var tournamentId = urlSearchParams.searchParams.get("seasonid")
        var divisionid = urlSearchParams.searchParams.get("divisionid")
        var teamid = urlSearchParams.searchParams.get("teamid")
        firebase.database().ref("seasons").child(tournamentId).child("divisions").child(divisionid).child("teams").child(teamid).on("value", (data) => {
            this.setState({ ...data.val(), seasonid: tournamentId, divisionid:divisionid, teamid:teamid })
            console.log(data.val())
            console.log(divisionid)
            // firebase.database().ref("matches").orderByChild("divisionid").equalTo(divisionid).on("value",(data1)=>{
            //     console.log(data1.val())
            //     this.setState({matches: data1.val()})
            // })
        })
        // firebase.database().ref("streamkeys").once("value", (streamkeys) =>{
        //     this.setState({streamkeys: streamkeys.val()})
        //     this.defaultnewMatchInfo.streamKey = streamkeys.val()["Main"]
        // })
        
    }

    TeamOptions = () => {
        return Object.keys(this.state.teams).map((homeTeamId) => {
            return (
                <option value={homeTeamId}>{this.state.teams[homeTeamId]["name"]}</option>
            )
        })
    }

    styles = {
        table: {
            maxWidth: 500,
            margin: 5
        }
    }
    //http://www.lapingpong.com/results/LeagueData.js
    

    render() {
        console.log(this.state)
        return (
            <div>
                
                <HomeAppBar title={this.state.divisionName}></HomeAppBar>
          
                <Card style={this.styles.table}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <h1>Players</h1>
                        <Button  onClick={() => {
                                                this.setState({ showAddPlayer: true, })
                                            }}>Add Player</Button>
                    </div>


                    {
                        console.log(this.state.teams)
                    }
                    
                    
                            



                                        <div>
                                        <Table>
                                           
                                            <TableBody>
                                                {this.state.players ? Object.keys(this.state.players).map((playerid) => {
                                                    return <TableRow>
                                                        <TableCell>{this.state.players[playerid].name}</TableCell>
                                                        <TableCell><Button onClick={()=>{
                                                            this.setState({showEditPlayer: true, editPlayer: playerid, editPlayerName: this.state.players[playerid].name})
                                                        }}>Edit</Button>
                                                        <Button onClick={()=>{
                                                            var removedPlayer = this.state.players
                                                            delete removedPlayer[playerid]
                                                            this.setState({players: removedPlayer, isPlayerRemoved:true })
                                                        }}>Remove</Button></TableCell>
                                                    </TableRow>
                                                }) : null
                                                }
                                                <TableRow>
                                                    <TableCell>
                                                         <Button disabled={!this.state.isPlayerRemoved}
                                                         onClick={()=>{
                                                            firebase.database().ref("seasons").child(this.state.seasonid).child("divisions").child(this.state.divisionid).child("teams").child(this.state.teamid).child("players").set(this.state.players) 
                                                            this.setState({isPlayerRemoved:false})
                                                         }}
                                                         >Save</Button>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                       
                                                </div>
                            
                          
                            
                        
                    
                    
                </Card>
                


     <Card style={this.styles.table}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Stream URL</TableCell>
                                        <TableCell>
                                        <input 
                                        onChange={(text)=>{
                                            this.setState({streamURL: text.target.value,valueChanged:true})
                                        }}
                                        value={this.state.streamURL}></input>
                                            </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Stream Key</TableCell>
                                        <TableCell><input 
                                        onChange={(text)=>{
                                            this.setState({streamKey: text.target.value, valueChanged:true})
                                        }}
                                        value={this.state.streamKey}></input></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Button disabled={!this.state.valueChanged}
                                            onClick={()=>{
                                                firebase.database().ref("seasons").child(this.state.seasonid).child("divisions").child(this.state.divisionid).child("teams").child(this.state.teamid).update({streamURL:this.state.streamURL,streamKey:this.state.streamKey})
                                                this.setState({valueChanged:false})
                                            }}
                                            >
                                                Save
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>

                            </Card>

                            <Card style={this.styles.table}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Jersey Color</TableCell>
                                        <TableCell>
                                        <input type={"color"}
                                        onChange={(text)=>{
                                            this.setState({jerseyColor: text.target.value,colorChanged:true})
                                        }}
                                        value={this.state.jerseyColor}></input>
                                            </TableCell>
                                    </TableRow>
                                    
                                    <TableRow>
                                        <TableCell>
                                            <Button disabled={!this.state.colorChanged}
                                            onClick={()=>{
                                                firebase.database().ref("seasons").child(this.state.seasonid).child("divisions").child(this.state.divisionid).child("teams").child(this.state.teamid).update({jerseyColor:this.state.jerseyColor})
                                                this.setState({colorChanged:false})
                                            }}
                                            >
                                                Save
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>

                            </Card>

                           






                {
                    /**
                     * 
                     * DIALOGS START HERE
                     */
                }
                

                


                <Dialog open={this.state.showAddPlayer}>
                    <Table>
                        <TableHead>
                            <TableRow><TableCell>Add Player To Team</TableCell></TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Player Name</TableCell>
                                <TableCell><input onChange={(ev) => {
                                    this.setState({ newplayerInfo: { ...this.state.newplayerInfo, name: ev.target.value } })
                                }} type="text"></input></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><Button onClick={() => {
                                    firebase.database().ref("seasons").child(this.state.seasonid).child("divisions").child(this.state.divisionid).child("teams").child(this.state.teamid).child("players").push(this.state.newplayerInfo)
                                    this.setState({ newplayerInfo: {}, showAddPlayer: false })
                                }}
                                >Save</Button></TableCell>
                                <TableCell><Button onClick={() => this.setState({ showAddPlayer: this.state.showAddPlayer ? false : true })}>Close</Button></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Dialog>

                <Dialog open={this.state.showEditPlayer}>
                    <Table>
                        <TableHead>
                            <TableRow><TableCell>Edit Player</TableCell></TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Player Name</TableCell>
                                <TableCell><input value={this.state.editPlayerName} onChange={(ev) => {
                                    this.setState({ editPlayerName: ev.target.value })
                                }} type="text"></input></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><Button onClick={() => {
                                    firebase.database().ref("seasons").child(this.state.seasonid).child("divisions").child(this.state.divisionid).child("teams").child(this.state.teamid).child("players").child(this.state.editPlayer).update({name: this.state.editPlayerName})
                                    this.setState({editPlayerName:"", showEditPlayer: false })
                                }}
                                >Save</Button></TableCell>
                                <TableCell><Button onClick={() => this.setState({ showEditPlayer: this.state.showEditPlayer ? false : true })}>Close</Button></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Dialog>





            </div>
        )
    }
}